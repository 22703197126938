<template>
    <div style="padding-bottom: 50px;">
        <div v-show="!isEmployee" class="addApprove">
            <div>
                <van-form @submit="save" :colon='true'>
                    <van-field
                        required
                        readonly
                        clickable
                        name="flow_id"
                        label="申请类型"
                        :value="flow_id.label"
                        placeholder="点击选择"
                        @click="showDropdown"
                        class="addApprove-van-field-boeder"
                        :rules="[{ required: true, message: '请选择' }]"
                    />
                    <van-field
                        v-model="content"
                        rows="2"
                        autosize
                        required
                        label="申请内容"
                        name="content"
                        type="textarea"
                        maxlength="1000"
                        placeholder="请输入申请内容"
                        show-word-limit
                        class="addApprove-van-textarea-boeder"
                        :rules="[{ required: true, message: '请输入' }]"
                    />

                    <van-field
                        name="imgList"
                        label="附件上传"
                        >
                        <template #input>
                            <van-uploader :after-read="e=>afterRead(e)" :before-delete='(e,b)=>beforeDelete(e,b)' multiple :max-count="9" v-model="imageListF" />
                        </template>
                    </van-field>
                    <van-button class="addApprove-apply" type="info" native-type="submit" block>提交</van-button>
                </van-form>
            </div>
            <div class="addApprove-wrap">
                <div class="addApprove-wrap-nav"><span style="color:red">*</span> 审批人</div>
                <div class="addApprove-wrap-avatarList">
                    <div v-for="(item,index) in approve_check_names" :key="item.employee_id" class="addApprove-wrap-avatar">
                        <avatar :src="item.employee_avatar" color="#fff" :rounded="false" :size="52"></avatar>
                        <div style="text-align:center;margin-top:3px">{{item.filter_name}}</div>
                        <van-icon size="16" class="addApprove-wrap-close" name="clear" @click="deleteEmployee(['approve_check_ids','approve_check_names'],index)" />
                    </div>
                    <div class="van-hairline--surround addApprove-wrap-icon" @click="showEmployee(['approve_check_ids','approve_check_names'],1)">
                        <van-icon size="24" color="#ebedf0" name="plus"/>
                    </div>
                </div>
            </div>
            <div class="addApprove-wrap">
                <div class="addApprove-wrap-nav">相关人</div>
                <div class="addApprove-wrap-avatarList">
                    <div v-for="(item,index) in relevant_check_names" :key="item.employee_id" class="addApprove-wrap-avatar">
                        <avatar :src="item.employee_avatar" color="#fff" :rounded="false" :size="52"></avatar>
                        <div style="text-align:center;margin-top:3px">{{item.filter_name}}</div>
                        <van-icon size="16" class="addApprove-wrap-close" name="clear" @click="deleteEmployee(['relevant_check_ids','relevant_check_names'],index)" />
                    </div>
                    <div class="van-hairline--surround addApprove-wrap-icon" @click="showEmployee(['relevant_check_ids','relevant_check_names'],2)">
                        <van-icon size="24" color="#ebedf0" name="plus" />
                    </div>
                </div>
            </div>
        </div>
        <employee v-show="isEmployee" :isTab="isTab" :check_id_List="check_id_List" :check_name_List="check_name_List" @toSubmit="hideEmployee" />
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                show-toolbar
                value-key='label'
                :columns="approveContent"
                @confirm="e=>onConfirm(e)"
                @cancel="showPicker = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import Avatar from 'vue-avatar'
    import employee from './employee'
    import upload from '@/utils/upload'
    import { Form, Field, Picker, Popup, Uploader} from 'vant'
    export default {
        name:"addApprove",
        data() {
            return {
                isTab:-1,
                flow_id: '',
                content: '',
                imageList:[],
                imageListF:[],
                check_id_List:[],
                check_name_List:[],
                isEmployee:false,
                showPicker:false,
                approve_check_ids:[],
                approve_check_names:[],
                relevant_check_ids:[],
                relevant_check_names:[],
                showEmployeeName:[],
                approveContent:[
                    {label:'普通审批',value:1},
                    {label:'请假审批',value:2},
                    {label:'出差审批',value:3},
                    {label:'加班审批',value:4},
                    {label:'差旅报销',value:5},
                    {label:'借款申请',value:6},
                    {label:'合同退费审批流程',value:100},
                ]
            }
        },
        components: {
            [Field.name]: Field,
            [Form.name]: Form,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Uploader.name]: Uploader,
            [Avatar.name]: Avatar,
            employee
        },
        methods: {
            save() {
                if(this.approve_check_ids.length === 0 ){
                    this.$toast('请选择审批人！！')
                    return false
                }
                let obj = {
                    category_id: this.flow_id.value,
                    content: this.content,
                    check_employee_id:this.approve_check_ids[0],
                    images:this.imageList
                }
                this.$api.oa_examine_add(obj)
                .then(res=>{
                    this.$toast('操作成功！！')
                })
            },
            showDropdown(){
                this.showPicker = true
            },
            onConfirm(e){
                this.flow_id = e
                this.showPicker = false
            },
            afterRead(file) {
                this.$forceUpdate()
                let that = this;
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        that.imageList.push(res.fileName)
                    })
                    .catch(err => {
                        console.error(err)
                    })
                }else{
                    file.forEach(it=>{
                        upload({ file: it.file })
                            .then(res => {
                                that.imageList.push(res.fileName)
                            })
                            .catch(err => {
                                console.error(err)
                            })
                    })
                }
            },
            beforeDelete(file,detail){
                this.imageList.splice(detail.index,1)
                this.$forceUpdate()
                return true
            }, 
            showEmployee(arr,num){
                this.showEmployeeName = arr
                this.check_id_List = this[arr[0]]
                this.check_name_List = this[arr[1]]
                this.isTab = num
                this.isEmployee = true
            },
            hideEmployee(num,ids,names){
                if(num){
                    this[this.showEmployeeName[0]] = ids
                    this[this.showEmployeeName[1]] = names
                }
                this.isTab = -1
                this.isEmployee = false
            },
            deleteEmployee(arr,index){
                this[arr[0]].splice(index,1)
                this[arr[1]].splice(index,1)
            }
        },
    }
</script>

<style lang="less">
    .addApprove{
        .van-cell{
            display: block;
        }
        .van-cell:not(:last-child)::after{
            border-bottom:none
        }
        .van-field__label{
            width: 100%;
        }
        .addApprove-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .addApprove-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        &-wrap{
            background: #fff;
            padding: 10px;
            margin-top: 10px;
            &-nav{
                color: #333;
                font-size: 14px;
                margin-bottom: 10px;
            }
            &-avatarList{
                display: flex;
                flex-wrap: wrap;
            }
            &-avatar{
                font-size: 12px;
                color: #666;
                margin: 5px 15px 5px 0;
                position: relative;
            }
            &-close{
                position: absolute;
                top: -8px;
                right: -8px;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                margin: 5px 15px 5px 0;
            }
        }
        &-apply{
            position: absolute;
            bottom: -30px;
        }
    }

</style>