<template>
    <div class="employeeApprove">
        <van-nav-bar
            title=""
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div class="employeeApprove-list">
            <van-cell @click="tocheck(item)" v-for="(item,index) in list" :key="index">
                <template #title>
                    <div class="employeeApprove-list-info">
                        <div style="margin-right:5px">
                            <van-checkbox checked-color="#07c160" :value="check_ids.indexOf(item.employee_id) != -1"></van-checkbox>
                        </div>
                        <div>
                            <avatar :src="item.employee_avatar" color="#fff" :size="36"></avatar>
                        </div>
                        <div>
                            <div>{{item.filter_name}}</div>
                        </div>
                    </div>
                </template>
            </van-cell>
        </div>
        <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        <div>
            <van-submit-bar label='已选：' :price="check_ids.length * 100" :disabled='check_ids.length === 0' currency="" :decimal-length='0' button-text="确认" @submit="onSubmit"></van-submit-bar>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { Empty, Tag, SubmitBar, Checkbox, NavBar} from 'vant'
    import Avatar from 'vue-avatar'
    import Loadings from '@/components/loading'
    export default {
        name:"employee",
        data() {
            return {
                list: [],
                check_ids:[],
                check_names:[],
            }
        },
        props:{
            isTab:Number,
            check_id_List:Array,
            check_name_List:Array,
        },
        components: {
            [Empty.name]: Empty,
            [Tag.name]: Tag,
            [NavBar.name]: NavBar,
            [Checkbox.name]: Checkbox,
            [SubmitBar.name]: SubmitBar,
            [Avatar.name]: Avatar,
            loadings:Loadings,
        },
        created () {
            this.getList()
        },
        methods: {
            getList(){
                this.$api.crm_filter_employee()
                .then(res=>{
                    this.list = res.data
                })
            },
            tocheck(item) {
                let index = this.check_ids.indexOf(item.employee_id)
                if(index == -1){
                    //多选
                    // this.check_ids.push(item.employee_id)
                    // this.check_names.push(item)
                    // 单选
                    this.check_ids = [item.employee_id]
                    this.check_names = [item]
                }else{
                    this.check_ids.splice(index,1)
                    this.check_names.splice(index,1)
                }
            },
            onSubmit(){
                this.$emit('toSubmit',1,this.check_ids,this.check_names)
            },
            returnCheck(item){
                check_ids.indexOf(item.employee_id) != -1
            },
            onClickLeft(){
                this.$emit('toSubmit',0)
            }

        },
        watch: {
            isTab() {
                this.check_ids = this.check_id_List 
                this.check_names = this.check_name_List
            }
        },
    }
</script>

<style lang="less">
.employeeApprove{
    min-height: 100vh;
    background: #fff;
    &-list{
        padding-bottom: 50px;
        &-info{
            display: flex;
            align-items: center;
            position: relative;
            .vue-avatar--wrapper{
                margin-right: 10px;
            }
        }
        .van-cell{
            align-items: center;
            background-color: rgba(0,0,0,0);
        }
        .van-tag--default{
            margin-right: 5px;
            color: #888;
            font-size: 10px;
        }
    }
}
</style>